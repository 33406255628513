<div class="team-member-container">
  <div class="team-member-nav-holder">
    @if (logoImgUrl.length !== 0) {
    <div class="icon-holder" (click)="navigateToHome()">
      <img id="logo-icon" [src]="logoImgUrl" alt="Logo icon" />
    </div>
    } @if (!userIsLoggedIn) {
    <div class="menu-profile-holder landing">
      <a class="link-button" class="link-button" routerLink="/{{ textConstants.APP_URL_ROUTE_LANDING_HOME }}">Home</a>
      <a class="link-button" routerLink="/{{ textConstants.APP_URL_ROUTE_LANDING_COURSES }}">Course Page</a>
      <a class="link-button" routerLink="/{{ textConstants.APP_URL_ROUTE_LANDING_ABOUT_US }}">About us</a>
      <!-- <a class="link-button" routerLink="/pricing">Pricing</a> -->
      <a class="link-button" routerLink="/{{ textConstants.APP_URL_ROUTE_LANDING_FAQ }}">FAQ</a>
      <a class="link-button" routerLink="/{{ textConstants.APP_URL_ROUTE_LANDING_CONTACT_US }}">Contact Us</a>
      <a class="link-button" routerLink="/auth">Log in</a>
    </div>
    } @if (userIsActive) {
    <div class="menu-profile-holder">
      <a class="link-button" (click)="onLogoutClick()">Log out</a>
      <a class="link-button" (click)="navigateToCourse()">{{ textConstants.MY_COURSES }}</a>
      <img src="assets/images/profile.svg" alt="Profile icon" />
    </div>
    }
  </div>
  <ng-content />

  @if (branding !== null) {
  <div class="team-member-footer">
    <div class="footer-logo-holder">
      @if (footerLogoImgUrl.length !== 0) {
      <img id="footer-img" [src]="footerLogoImgUrl" alt="Footer logo" />
      }
      <div class="links-holder">
        <a
          [ngStyle]="{ 'pointer-events': branding.footerLinks[0].link.length === 0 ? 'none' : '' }"
          [href]="getLink(branding.footerLinks[0].link)"
          class="link"
          target="_blank"
        >
          {{ branding.footerLinks[0].text || 'Data privacy' }}
        </a>
        <a
          [ngStyle]="{ 'pointer-events': branding.footerLinks[1].link.length === 0 ? 'none' : '' }"
          [href]="getLink(branding.footerLinks[1].link)"
          class="link"
          target="_blank"
        >
          {{ branding.footerLinks[1].text || 'Terms of use' }}
        </a>
      </div>
    </div>

    <div class="footer-center-content">
      <div class="free-text" [innerHTML]="branding.footerFreeText"></div>
      <div class="powered-by">
        <div class="powered-text">Powered by</div>
        <img width="100px" [src]="textConstants.WLD_LOGO_WHITE_IMG_PATH" alt="WLD logo" />
      </div>
    </div>

    <div class="other-links-holder">
      @for (link of branding.footerLinks.slice(2, 6); track $index) { @if (link.link.length > 0 || link.text.length > 0) { @if (['Free Input',
      'Phone'].includes(link.type)) {
      <div class="link other-text">
        {{ link.text }}
      </div>
      } @else {
      <a
        class="link"
        [href]="link.type === 'Email' ? 'mailto:' + link.text : !link.link.startsWith('http') ? '//' + link.link : link.link"
        target="_blank"
        >{{ link.text || link.link }}</a
      >
      } } }
    </div>
  </div>
  }
</div>

import { Component, ComponentRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { ECourseOverviewDropdownComponent } from '../e-course-overview-dropdown/e-course-overview-dropdown.component';
import { ModulesService } from '../../../../services/modules.service';
import { CoursesService } from '../../../../services/courses.service';
import { WelcomeFilesService } from '../../../../services/welcome-files.service';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs';

@Component({
  selector: 'app-e-course-overview-dropdown-section',
  templateUrl: './e-course-overview-dropdown-section.component.html',
  styleUrl: './e-course-overview-dropdown-section.component.scss',
})
export class ECourseOverviewDropdownSectionComponent implements OnInit {
  modulesData!: any[];
  isLocked!: boolean;
  @Input() courseId!: string;
  isDropdownOpen!: boolean;
  @ViewChild('sectionContainer', { read: ViewContainerRef }) sectionContainerRef!: ViewContainerRef;
  sections: Array<ComponentRef<ECourseOverviewDropdownComponent>> = [];
  imageUrl: any;
  @Output() selectedContent = new EventEmitter<any>();

  constructor(
    private moduleService: ModulesService, 
    private courseService: CoursesService, 
    private welcomeService: WelcomeFilesService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.getCourse();
  }

  getCourse() {
    this.courseService.getById(this.courseId).subscribe((course) => {
      this.welcomeService.getByIdAndUpdateUrl(course.imageFileId, 'welcomefiles').pipe(first()).subscribe((info) => {
        this.imageUrl = info.url;
        this.getModules();
      });
    });
  }

  getModules() {
    this.moduleService.getAllModules(this.courseId).subscribe((modules) => {
      this.modulesData = modules;
      setTimeout(() => {
        for (let index = 0; index < this.modulesData.length; index++) {
          const element = this.modulesData[index];
          const currentComponent = this.sectionContainerRef?.createComponent(ECourseOverviewDropdownComponent);
          element.subtitle = element.subtitle.replace(/(<([^>]+)>)/gi, '');

          if (index == 0) {
            currentComponent.instance.isFirstModule = true;
          }
          currentComponent.instance.modulesData = element;
          currentComponent.instance.videoThumbnailImageUrl = this.imageUrl;
          currentComponent.instance.moduleId = element.id;

          currentComponent.instance.sectionRemoved.subscribe(() => {
            this.removeSection(currentComponent);
          });

          currentComponent.instance.selectedContentOutput.subscribe((eventData) => {
            this.handleSelectedContent(eventData);
          });

          this.sections.push(currentComponent);
        }
      });
    });
  }

  handleSelectedContent(selectedContentId: any) {
    this.selectedContent.emit(selectedContentId);
  } 

  continueLearning() {
    this.router.navigate(['video'], {relativeTo: this.route})
  }

  removeSection(componentRef: ComponentRef<ECourseOverviewDropdownComponent>): void {
    const index = this.sections.findIndex((c) => c === componentRef);

    this.sections[index].destroy();
    this.sections.splice(index, 1);
  }
}

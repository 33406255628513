<app-e-team-member-layout>
  <div id="password-reset-page">
    <div id="auth-container">
      @if (resetToken.length === 0) {
      <div id="auth-header">
        <div id="auth-title">Forgot your password</div>
        <div id="auth-subtitle">Enter your email</div>
      </div>

      <div id="auth-form">
        <app-e-input
          [label]="'Email'"
          [placeholderContent]="textConstants.INPUT_PLACEHOLDER"
          [inputOnEnter]="true"
          (valueChanged)="onEmailChange($event)"
          [(ngModel)]="email"
          (enterPressed)="sendEmail()"
        />

        @if (successMessage.length > 0) {
        <div id="auth-success-message">{{ successMessage }}</div>
        }

        <app-e-button [textContent]="'Send email'" [disabled]="email.length === 0" (btnClick)="sendEmail()" />
      </div>
      } @else {
      <div id="auth-header">
        <div id="auth-title">Reset your password</div>
        <div id="auth-subtitle">MedLearn Pro</div>
      </div>

      <div id="auth-form">
        <app-e-input
          [label]="'New Password'"
          [placeholderContent]="textConstants.INPUT_PLACEHOLDER"
          [isPassword]="true"
          [inputOnEnter]="true"
          (valueChanged)="onNewPasswordChange($event)"
          [(ngModel)]="newPassword"
          (enterPressed)="resetPassword()"
        />

        <app-e-input
          [label]="'Repeat Password'"
          [placeholderContent]="textConstants.INPUT_PLACEHOLDER"
          [isPassword]="true"
          [inputOnEnter]="true"
          (valueChanged)="onRepeatPasswordChange($event)"
          [(ngModel)]="repeatPassword"
          (enterPressed)="resetPassword()"
        />

        @if (errorMessage.length > 0) {
        <div id="auth-error-message">{{ errorMessage }}</div>
        } @if (successMessage.length > 0) {
        <div id="auth-success-message">{{ successMessage }}</div>
        }

        <app-e-button
          [textContent]="'Reset password'"
          [disabled]="newPassword.length === 0 || repeatPassword.length === 0"
          (btnClick)="resetPassword()"
        />
      </div>
      }
    </div>
  </div>
</app-e-team-member-layout>

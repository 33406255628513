import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, map } from 'rxjs';
import { ImageAttributes } from '../models/Carousels';
import { ApiService } from './api.service';
import * as TextConstants from '../constants/text.constants';

@Injectable({
  providedIn: 'root',
})
export class CarouselService {
  textConstants = TextConstants;
  private imagesSource = new BehaviorSubject<ImageAttributes[]>([]);
  currentImages = this.imagesSource.asObservable();
  private activeIndexSource = new BehaviorSubject<number>(0);
  activeIndex = this.activeIndexSource.asObservable();

  constructor(private apiService: ApiService) {}

  setActiveIndex(index: number): void {
    this.activeIndexSource.next(index);
  }

  addImage(newImage: ImageAttributes) {
    const currentImages = this.imagesSource.getValue();

    this.imagesSource.next([...currentImages, newImage]);
    this.setActiveIndex(currentImages.length);
  }

  getCurrentImage(): ImageAttributes {
    const currentImages = this.imagesSource.getValue();
    const index = this.activeIndexSource.getValue();

    return currentImages[index];
  }

  uploadImage(imageUrl: string, imageContent?: string, fileName?: string): void {
    let file;
    if (imageContent && fileName) {
      const binaryString = atob(imageContent);

      const arrayBuffer = new ArrayBuffer(binaryString.length);
      const intArray = new Uint8Array(arrayBuffer);

      for (let i = 0; i < binaryString.length; i++) {
        intArray[i] = binaryString.charCodeAt(i);
      }

      const blob = new Blob([arrayBuffer], { type: 'image/png' });
      file = new File([blob], fileName, { type: blob.type });
    }

    const newImage: ImageAttributes = {
      src: imageUrl,
      alt: 'Newly added image',
      file: file,
    };

    this.addImage(newImage);
  }

  loadInitialImages(images: ImageAttributes[]) {
    const imageFetchObservables = images.map((image) => {
      return this.apiService.uploadFile(image.src).pipe(
        map((blob) => {
          const filename = image.src.split('/').pop() ?? '';
          const file = new File([blob], filename, { type: blob.type });
          return { ...image, file: file };
        })
      );
    });

    forkJoin(imageFetchObservables).subscribe((imagesWithFiles) => {
      this.imagesSource.next(imagesWithFiles);
    });
  }
}

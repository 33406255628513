<app-e-team-member-layout>
  <div id="page-login">
    <div id="auth-container">
      <div id="auth-header">
        <div id="auth-title">Log in to your</div>
        <div id="auth-subtitle">MedLearn Pro account</div>
      </div>

      <div id="auth-form">
        <app-e-input
          [label]="'Email'"
          [placeholderContent]="textConstants.INPUT_PLACEHOLDER"
          [inputOnEnter]="true"
          (valueChanged)="onEmailChange($event)"
          [(ngModel)]="email"
          (enterPressed)="onSubmit()"
        />
        <app-e-input
          [label]="'Password'"
          [placeholderContent]="textConstants.INPUT_PLACEHOLDER"
          [isPassword]="true"
          [inputOnEnter]="true"
          (valueChanged)="onPasswordChange($event)"
          [(ngModel)]="password"
          (enterPressed)="onSubmit()"
        />

        @if (error.length > 0) {
        <div id="auth-error-message">{{ error }}</div>
        }

        <app-e-button [textContent]="'LOGIN'" [disabled]="email.length === 0 || password.length === 0" (btnClick)="onSubmit()" />
      </div>

      <div id="forgot-password" [routerLink]="'/password-reset'">Have you forgotten your password?</div>
    </div>
  </div>
</app-e-team-member-layout>

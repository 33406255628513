import { Component, ComponentRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { ESectionsOverviewComponent } from '../e-sections-overview/e-sections-overview.component';
import { SectionsService } from '../../../../services/sections.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-e-course-overview-dropdown',
  templateUrl: './e-course-overview-dropdown.component.html',
  styleUrl: './e-course-overview-dropdown.component.scss',
})
export class ECourseOverviewDropdownComponent implements OnInit {
  isLocked!: boolean;
  isDropdownOpen: boolean = true;
  videoThumbnailImageUrl: string = '';
  sections!: any;
  @Input() modulesData!: any;
  @Input() isFirstModule: boolean = false;
  @Input() moduleId!: string;
  @Output() sectionRemoved = new EventEmitter<void>();
  @ViewChild('subSectionContainer', { read: ViewContainerRef }) sectionContainerRef!: ViewContainerRef;
  subSections: Array<ComponentRef<ESectionsOverviewComponent>> = [];
  @Output() selectedContentOutput = new EventEmitter<any>();

  constructor(private sectionService: SectionsService, private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.getSections();
  }

  getSections() {
    this.sectionService.getAllSections(this.moduleId).subscribe((sections) => {
      this.sections = sections;
      setTimeout(() => {
        for (let i = 0; i < this.sections.length; i++) {
          const element = this.sections[i];

          const currentComponent = this.sectionContainerRef?.createComponent(ESectionsOverviewComponent);

          if (i == 0 && this.isFirstModule == true) {
            currentComponent.instance.isFirstSection = true;
          }
          currentComponent.instance.data = element;
          currentComponent.instance.videoThumbnailUrl = this.videoThumbnailImageUrl;
          currentComponent.instance.isEndUser = true;

          currentComponent.instance.selectedContent.subscribe((eventData) => {
            this.handleChildEvent(eventData);
          });

          this.subSections.push(currentComponent);
        }
      });
    });
  }

  handleChildEvent(eventData: any) {
    this.selectedContentOutput.emit(eventData);
  }

  openModule(moduleId: string) {
    this.router.navigate(['video', moduleId], { relativeTo: this.route });
  }

  toggleDropdown() {
    this.isLocked = true;
    this.isDropdownOpen = !this.isDropdownOpen;
    if (this.isDropdownOpen == true) {
      this.getSections();
    }
  }
}

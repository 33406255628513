import { Component, ElementRef, OnInit } from '@angular/core';
import * as TextConstants from '../../../constants/text.constants';
import { WelcomeFilesService } from '../../../services/welcome-files.service';

@Component({
  selector: 'app-get-access',
  templateUrl: './get-access.component.html',
  styleUrl: './get-access.component.scss',
})
export class GetAccessComponent implements OnInit {
  videoElement!: ElementRef<HTMLVideoElement>;
  playState: boolean = false;
  videoFile: any;
  textConstants = TextConstants;

  constructor(private fileService: WelcomeFilesService) {}

  ngOnInit(): void {
    this.getVideo();
  }

  getVideo() {
    this.fileService.getGetAccessVideo('72280d81-f661-4565-b7e2-2a40ed14de64', 'contentmanager').subscribe((data) => {
      this.videoFile = data.url;
    });
  }

  onVideoElementUpdate(number: ElementRef<HTMLVideoElement>) {
    this.videoElement = number;
  }
}

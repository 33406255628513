<div #componentLoader>
  <div class="component-loader">
    <div class="overlay local"></div>
    <div class="loader local">
      <div>
        <mat-spinner #spinner class="mat-spinner-color" [diameter]="50"></mat-spinner>
      </div>
    </div>
  </div>
</div>

import { NgModule } from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { CarouselModule } from 'primeng/carousel';
import { ButtonModule } from 'primeng/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatStepperModule } from '@angular/material/stepper';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';

import { ELogoComponent } from '../admin/components/e-logo/e-logo.component';
import { EDropdownComponent } from '../../components/e-dropdown/e-dropdown.component';
import { EButtonComponent } from '../../components/e-button/e-button.component';
import { EInputComponent } from '../../components/e-input/e-input.component';
import { ELoaderComponent } from '../../components/e-loader/e-loader.component';
import { EPaginationComponent } from '../../components/e-pagination/e-pagination.component';
import { ECalendarComponent } from '../../components/e-calendar/e-calendar.component';
import { ECarouselComponent } from '../../components/e-carousel/e-carousel.component';
import { EErrorComponent } from '../../components/e-error/e-error.component';
import { EIconButtonComponent } from '../../components/e-icon-button/e-icon-button.component';
import { ETextAreaComponent } from '../../components/e-text-area/e-text-area.component';
import { EVideoPlayerComponent } from '../../components/e-video-player/e-video-player.component';
import { AuthComponent } from '../../pages/auth/auth.component';
import { PasswordResetComponent } from '../../pages/password-reset/password-reset.component';
import { ETabsComponent } from '../../components/e-tabs/e-tabs.component';
import { RouterModule } from '@angular/router';
import { EUploadVideoComponent } from '../../components/e-upload-video/e-upload-video.component';
import { EDynamicTextAreaComponent } from '../../components/e-dynamic-text-area/e-dynamic-text-area.component';
import { QuillModule } from 'ngx-quill';
import { NgOptimizedImage } from '@angular/common';
import { TabMenuModule } from 'primeng/tabmenu';
import { MenuModule } from 'primeng/menu';
import { ECourseOverviewDropdownSectionComponent } from '../admin/components/e-course-overview-dropdown-section/e-course-overview-dropdown-section.component';
import { ECourseOverviewDropdownComponent } from '../admin/components/e-course-overview-dropdown/e-course-overview-dropdown.component';
import { CourseOverviewComponent } from '../admin/components/course-overview/course-overview.component';
import { ENavigationTabsComponent } from '../../components/e-navigation-tabs/e-navigation-tabs.component';
import { MarkdownModule } from 'ngx-markdown';
import { AuthWsFedComponent } from './auth-ws-fed/auth-ws-fed.component';
import { EMapComponent } from '../../components/e-map/e-map.component';
import { GetAccessComponent } from './get-access/get-access.component';
import { ETeamMemberLayoutComponent } from '../../components/e-team-member-layout/e-team-member-layout.component';
@NgModule({
  declarations: [
    ENavigationTabsComponent,
    ELogoComponent,
    EDropdownComponent,
    EButtonComponent,
    EInputComponent,
    ELoaderComponent,
    EPaginationComponent,
    ECalendarComponent,
    ECarouselComponent,
    EErrorComponent,
    EIconButtonComponent,
    ETextAreaComponent,
    EVideoPlayerComponent,
    AuthComponent,
    ETabsComponent,
    EUploadVideoComponent,
    EDynamicTextAreaComponent,
    ECourseOverviewDropdownSectionComponent,
    ECourseOverviewDropdownComponent,
    EMapComponent,
    CourseOverviewComponent,
    AuthWsFedComponent,
    GetAccessComponent,
    PasswordResetComponent,
    ETeamMemberLayoutComponent,
  ],
  imports: [
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    VgBufferingModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    MatSidenavModule,
    MatIconModule,
    MatDividerModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatStepperModule,
    MatTableModule,
    MatRadioModule,
    MatInputModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatDialogModule,
    MatToolbarModule,
    MatAutocompleteModule,
    NgxPaginationModule,
    MatCheckboxModule,
    MatMenuModule,
    MatButtonToggleModule,
    ButtonModule,
    CarouselModule,
    ToggleButtonModule,
    SelectButtonModule,
    RouterModule,
    QuillModule,
    NgOptimizedImage,
    TabMenuModule,
    MenuModule,
    MarkdownModule.forRoot(),
  ],
  exports: [
    ENavigationTabsComponent,
    ELogoComponent,
    EDropdownComponent,
    EButtonComponent,
    EInputComponent,
    ELoaderComponent,
    EPaginationComponent,
    ECalendarComponent,
    ECarouselComponent,
    EErrorComponent,
    EIconButtonComponent,
    ETextAreaComponent,
    EVideoPlayerComponent,
    EUploadVideoComponent,
    AuthComponent,
    ETabsComponent,
    EUploadVideoComponent,
    EDynamicTextAreaComponent,
    ECourseOverviewDropdownSectionComponent,
    ECourseOverviewDropdownComponent,
    EMapComponent,
    CourseOverviewComponent,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    VgBufferingModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    MatSidenavModule,
    MatIconModule,
    MatDividerModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatStepperModule,
    MatTableModule,
    MatRadioModule,
    MatInputModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatDialogModule,
    MatToolbarModule,
    MatAutocompleteModule,
    NgxPaginationModule,
    MatCheckboxModule,
    MatMenuModule,
    MatButtonToggleModule,
    ButtonModule,
    CarouselModule,
    ToggleButtonModule,
    SelectButtonModule,
    RouterModule,
    QuillModule,
    TabMenuModule,
    MenuModule,
    PasswordResetComponent,
    ETeamMemberLayoutComponent,
  ],
})
export class SharedModule {}

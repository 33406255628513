import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import * as TextConstants from '../../constants/text.constants';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrl: './password-reset.component.scss',
})
export class PasswordResetComponent implements OnInit {
  resetToken: string = '';
  email: string = '';
  newPassword: string = '';
  repeatPassword: string = '';
  errorMessage: string = '';
  successMessage: string = '';
  textConstants = TextConstants;

  constructor(private route: ActivatedRoute, private authService: AuthService) {}

  ngOnInit(): void {
    this.resetToken = this.route.snapshot.queryParamMap.get('token') ?? '';
    this.email = this.route.snapshot.queryParamMap.get('email') ?? '';
  }

  onEmailChange(value: any) {
    this.email = value;
  }

  onNewPasswordChange(value: any) {
    this.newPassword = value;
  }

  onRepeatPasswordChange(value: any) {
    this.repeatPassword = value;
  }

  sendEmail() {
    if (this.email.length === 0) {
      return;
    }

    this.authService.forgotPassword(this.email).subscribe(() => (this.successMessage = 'Password reset link was sent to your email.'));
  }

  resetPassword() {
    if (this.newPassword.length === 0 && this.repeatPassword.length === 0 && this.resetToken.length === 0) {
      return;
    }

    if (this.newPassword !== this.repeatPassword) {
      this.errorMessage = 'The new password and confirmation password do not match!';
      return;
    }

    this.authService.resetPassword({ email: this.email, newPassword: this.newPassword, resetToken: this.resetToken }).subscribe({
      next: () => (this.successMessage = 'Password successfully changed.'),
      error: () => (this.errorMessage = 'Invalid reset request'),
    });
  }
}

import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { LoaderService } from '../../services/loader.service';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-e-loader',
  templateUrl: './e-loader.component.html',
  styleUrl: './e-loader.component.scss',
})
export class ELoaderComponent implements AfterViewInit, OnDestroy {
  private loadingSubscription = new Subscription();

  @ViewChild('componentLoader') componentLoaderRef!: ElementRef<HTMLElement>;
  @ViewChild('spinner') spinnerRef!: MatProgressSpinner;

  constructor(private loaderService: LoaderService) {}

  ngAfterViewInit() {
    this.componentLoaderRef.nativeElement.setAttribute('style', 'display: none');
    this.loaderService.setLocalLoaderTemplate(this.componentLoaderRef, this.spinnerRef);
  }

  ngOnDestroy() {
    this.loadingSubscription.unsubscribe();
  }
}

<div [ngClass]="dateState">
  <mat-form-field ngForm="form" appearance="fill" class="input-container" (click)="togglePicker(picker)">
    <mat-label class="input-label">Select due date</mat-label>
    <mat-date-range-input #pickerInput [rangePicker]="picker">
      <input #dateRangeStart [(ngModel)]="startDate" name="startDate" matStartDate readonly />
      <input #dateRangeEnd [(ngModel)]="endDate" name="endDate" (dateChange)="emitRange(dateRangeStart, dateRangeEnd)" matEndDate readonly />
    </mat-date-range-input>
    <mat-datepicker-toggle [for]="picker" matSuffix>
      <svg
        [ngClass]="{ rotate: picker.opened }"
        class="arrow"
        fill="none"
        height="7"
        matDatepickerToggleIcon
        viewBox="0 0 14 8"
        width="12"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path class="toggle-button" d="M1 1L7 7L13 1" stroke="#2E1B57" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </mat-datepicker-toggle>
    <mat-date-range-picker #picker [panelClass]="dateState" />
  </mat-form-field>
</div>

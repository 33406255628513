<div class="course-overview-container">
  <div class="course-overview-title">
    <h1 id="overview-title">COURSE OVERVIEW</h1>
    <button class="knowladge-button" (click)="continueLearning()">CONTINUE LEARNING</button>
  </div>

  @if (modulesData) {
  <ng-template #sectionContainer />
  }
</div>

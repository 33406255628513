import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { CarouselService } from '../../services/carousels.service';
import { Subject, takeUntil } from 'rxjs';

interface IImageComponent {
  src: string;
  alt: string;
}

@Component({
  selector: 'app-e-carousel',
  templateUrl: './e-carousel.component.html',
  styleUrl: './e-carousel.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class ECarouselComponent implements OnInit, OnDestroy {
  @Input() images: IImageComponent[] = [];
  activeIndex: number = 1;
  private unsubscribe$ = new Subject<void>();

  constructor(private carouselService: CarouselService, private cdRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.carouselService.currentImages.pipe(takeUntil(this.unsubscribe$)).subscribe((images) => {
      this.images = images;
    });

    this.carouselService.activeIndex.pipe(takeUntil(this.unsubscribe$)).subscribe((index) => {
      this.activeIndex = index;
      this.cdRef.detectChanges();
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onPageChange(event: any): void {
    this.carouselService.setActiveIndex(event.page);
  }
}

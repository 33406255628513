<div class="module-container app-scroll">
  <div class="module-section">
    <div class="module-upper-part">
      <div class="module-title-holder">
        <div class="module-title">{{ modulesData.title }}</div>
      </div>

      <div class="actions-holder">
        <div class="info-holder">
          <img src="assets/images/time-completed-icon.svg" alt="" />
          {{ modulesData.moduleDuration }}
        </div>
        <div class="info-holder">
          <img src="assets/images/percentage-completed-icon.svg" alt="" />
          {{ modulesData.moduleProgress + '%' }}
        </div>
        <app-e-button (click)="toggleDropdown()" btnClass="nested-content">
          <img
            [ngClass]="{ rotate: true, down: isDropdownOpen }"
            alt="Dropdown Button"
            height="26"
            src="assets/images/select_icon_light_purple_down.svg"
            width="27"
          />
        </app-e-button>
      </div>
    </div>
    <div class="module-subtitle" [innerHTML]="modulesData.subtitle"></div>
  </div>
  @if (isDropdownOpen) {

  <ng-template #subSectionContainer />

  }
</div>

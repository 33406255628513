import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDateRangePicker } from '@angular/material/datepicker';

@Component({
  selector: 'app-e-calendar',
  templateUrl: './e-calendar.component.html',
  styleUrls: ['./e-calendar.component.scss'],
})
export class ECalendarComponent implements OnInit {
  @Output() rangeSelect = new EventEmitter<{
    startDate: Date | null;
    endDate: Date | null;
  }>();
  isPickerOpened = false;
  @Input() type: string = 'dueDate';
  @Input() startDate!: Date;
  @Input() endDate!: Date;

  constructor() {}

  ngOnInit(): void {

  }

  get dateState(): string {
    switch (this.type) {
      case 'isActive':
        return 'isActive';
      case 'isNotActive':
        return 'isNotActive';
      case 'veryCloseDueDate':
        return 'veryCloseDueDate';
      case 'closeDueDate':
        return 'closeDueDate';
      default:
        return 'dueDate';
    }
  }

  emitRange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
    this.rangeSelect.emit({
      startDate: new Date(dateRangeStart.value),
      endDate: new Date(dateRangeEnd.value),
    });
  }

  togglePicker(picker: MatDateRangePicker<Date>) {
    if (!picker.disabled) {
      this.isPickerOpened = !this.isPickerOpened;
      picker.open();
    }
  }
}

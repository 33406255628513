import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { CountriesService } from '../../services/countries.service';
import { RegionsService } from '../../services/regions.service';
import { BaseOption } from '../../submodules/admin/models/admin-team-lead/admin-team-lead-team-option.model';

interface Region {
  name: string;
  countries: string[];
  activeCountries: string[];
}

@Component({
  selector: 'app-e-map',
  templateUrl: './e-map.component.html',
  styleUrls: ['./e-map.component.scss'],
})
export class EMapComponent {
  regions: Region[] = [];

  @Input() highlightedCountries: string[] = [];
  @Output() countries = new EventEmitter<string[]>();

  constructor(private router: Router, private regionsService: RegionsService, private countriesService: CountriesService) {}

  ngOnInit(){
    this.loadRegionsAndCountries();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['highlightedCountries']) {
      this.updateActiveCountries(changes['highlightedCountries'].currentValue);
    }
  }

  loadRegionsAndCountries(): void {
    this.regionsService.getAll().subscribe((regions: BaseOption[]) => {
      regions.forEach((region) => {
        this.countriesService.getAllByRegionId(region.id).subscribe((countries: BaseOption[]) => {
          this.regions.push({
            name: region.name === 'North America' ? 'NorthAmerica' : region.name,
            countries: countries.map((country) => country.name),
            activeCountries: [],
          });
        });
      });
    });
  }

  updateActiveCountries(countries: string[]): void {
    this.regions.forEach((region) => {
      const allCountry = region.countries.find((country) => country.startsWith('All '));

      if (allCountry && countries.includes(allCountry)) {
        region.activeCountries = [...region.countries];
      } else {
        region.activeCountries = region.countries.filter((country) => countries.includes(country));
      }
    });
  }

  markUnmarkCountry(regionName: string, countryName: string): void {
    const region = this.regions.find((r) => r.name === regionName);
    if (region) {
      const allCountry = region.activeCountries.find((country) => country.startsWith('All '));
      const regions = allCountry?.split('All ');
      if (regions?.includes(regionName)) {
        region.activeCountries = [];
      } else {
        const index = region.activeCountries.indexOf(countryName);
        if (index !== -1) {
          region.activeCountries.splice(index, 1);
        } else {
          region.activeCountries.push(countryName);
        }
      }
    }

    const countries: string[] = [];
    this.regions.forEach((region) => {
      if (region.activeCountries.length > 0) {
        if (!region.activeCountries.some((country) => country.startsWith('All '))) {
          region.activeCountries.forEach((country) => {
            countries.push(country);
          });
        } else {
          region.activeCountries.forEach((country) => {
            if (country.startsWith('All ')) {
              countries.push(country);
            }
          });
        }
      }
    });

    this.countries.emit(countries);
  }

  isCountryActive(regionName: string, countryName: string): boolean {
    const region = this.regions.find((r) => r.name === regionName);
    return !!region && region.activeCountries.includes(countryName);
  }
}

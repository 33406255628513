<app-e-team-member-layout>
  <div class="page-access">
    <div class="welcome-header">
      <h2 id="welcome-title">{{ textConstants.MEMBER_WELCOME_MESSAGE }}</h2>
    </div>

    <div class="get-access">
      @if (videoFile.length !== 0) {
      <app-e-video-player
        height="560px"
        [videoFile]="videoFile"
        width="840px"
        [isAdmin]="true"
        [playVideo]="playState"
        (videoElementAdded)="onVideoElementUpdate($event)"
      />
      } @else {
      <img id="welcome-image" [src]="textConstants.DEF_USER_MANAGEMENT_PIC" alt="Welcome Message Image" width="840px" height="560px" />
      }
      <!-- [videoFile]="videoFile ? videoFile : videoUrl" -->

      <div class="get-access-info-holder">
        <div class="access-title">GET ACCESS</div>
        <div class="access-information">
          <div>
            To access our e-learning trainings, please contact our Learning Manager at
            <a class="mail-to" [href]="'mailto:'.concat(textConstants.CONTACT_EMAIL)">{{ textConstants.CONTACT_EMAIL }}</a>
            . We look forward to seeing you soon and wish you success in your learning activities!
          </div>
        </div>
      </div>
    </div>
  </div>
</app-e-team-member-layout>

import { Component, OnInit } from '@angular/core';

import * as TextConstants from '../../constants/text.constants';
import { Branding } from '../../models/Branding';
import { BrandingService } from '../../services/branding.service';
import { WelcomeFilesService } from '../../services/welcome-files.service';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-e-team-member-layout',
  templateUrl: './e-team-member-layout.component.html',
  styleUrl: './e-team-member-layout.component.scss',
})
export class ETeamMemberLayoutComponent implements OnInit {
  branding: Branding | null = null;
  logoImgUrl: string = '';
  footerLogoImgUrl: string = '';
  userIsLoggedIn: boolean = false;
  userIsActive: boolean = false;
  textConstants = TextConstants;

  constructor(
    private router: Router,
    private authService: AuthService,
    private brandingService: BrandingService,
    private mediaFileService: WelcomeFilesService
  ) {}

  ngOnInit(): void {
    this.getBranding();
    this.userIsLoggedIn = this.authService.isLoggedIn;
    this.userIsActive = this.authService.userIsActive;
  }

  navigateToHome() {
    if (this.userIsActive) {
      this.router.navigate([TextConstants.APP_URL_ROUTE_TEAM_MEMBER_HOME]);
    }
  }

  navigateToCourse() {
    this.router.navigate([TextConstants.APP_URL_ROUTE_TEAM_MEMBER_HOME], { fragment: 'course-catalog' });
  }

  onLogoutClick() {
    this.authService.logout();
    this.router.navigate(['auth']);
  }

  getLink(url: string) {
    return url.startsWith('http') ? url : '//' + url;
  }

  getBranding() {
    this.brandingService.getBranding().subscribe((branding) => {
      this.branding = branding;

      if (branding.logoFileId !== null) {
        this.getImage(branding.logoFileId).subscribe((info) => (this.logoImgUrl = info.url));
      } else {
        this.logoImgUrl = TextConstants.WLD_LOGO_BLACK_IMG_PATH;
      }

      if (branding.footerLogoFileId !== null) {
        this.getImage(branding.footerLogoFileId).subscribe((info) => (this.footerLogoImgUrl = info.url));
      } else {
        this.footerLogoImgUrl = TextConstants.WLD_LOGO_MAIN_IMG_PATH;
      }
    });
  }

  getImage(imgId: string) {
    return this.mediaFileService.getByIdAndUpdateUrl(imgId, 'welcomefiles');
  }
}

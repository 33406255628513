<pagination-template #p="paginationApi" (pageChange)="changePage($event)" class="pagination_container">
  <div class="pagination-container">
    <div [class.disabled]="p.isFirstPage()" class="page-nav">
      <a (click)="p.previous()">
        <img alt="left pagination button" src="assets/images/left_pagination_button.svg" />
      </a>
    </div>

    @for (page of p.pages; track page) {
    <div [class.current]="p.getCurrent() === page.value" class="page-list">
      @if (p.getCurrent() !== page.value) {
      <a (click)="p.setCurrent(page.value)" class="page-number">
        <span>{{ page.label }}</span>
      </a>
      } @if (p.getCurrent() === page.value) {
      <div>
        <span>{{ page.label }}</span>
      </div>
      }
    </div>
    }

    <div [class.disabled]="p.isLastPage()" class="page-nav">
      <a (click)="!p.isLastPage() && p.next()">
        <img alt="right pagination button" src="assets/images/right_pagination_button.svg" />
      </a>
    </div>
  </div>
</pagination-template>

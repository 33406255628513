import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-auth-ws-fed',
  templateUrl: './auth-ws-fed.component.html',
  styleUrl: './auth-ws-fed.component.scss',
})
export class AuthWsFedComponent implements OnInit {
  token!: string;
  returnUrl!: string;
  constructor(private router: Router, private authService: AuthService, private route: ActivatedRoute, private cookieService: CookieService) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.token = params['token'];
      this.returnUrl = params['returnUrl'];

      if (this.token != null) {
        this.cookieService.set(
          '_elpat',
          this.token,
          0.5, // Expires in 12 hours
          '/', // Path
          '', // Domain (empty means current domain)
          true, // Secure flag
          'Lax'
        );
        this.authService.setUserData();

        if (this.cookieService.get('_elpat')) {
          if (!this.authService.userIsActive) {
            this.router.navigate(['/get-access']);
          } else {
            this.router.navigate([this.returnUrl.replace(window.location.origin, '')]);
          }
        }
      }
    });
    // var token = this.route.snapshot.paramMap.get("token");
  }
}

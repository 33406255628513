import { Component } from '@angular/core';
import { Router } from '@angular/router';

import * as TextConstants from '../../constants/text.constants';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrl: './auth.component.scss',
})
export class AuthComponent {
  email: string = '';
  password: string = '';
  error: string = '';
  textConstants = TextConstants;

  constructor(private authService: AuthService, private router: Router) {}

  onEmailChange(value: any) {
    this.email = value;
  }

  onPasswordChange(value: any) {
    this.password = value;
  }

  onSubmit() {
    if (this.email.length === 0 || this.password.length === 0) {
      return;
    }

    this.error = '';

    this.authService.signup({ email: this.email, password: this.password }).subscribe({
      next: () => {
        this.authService.setUserData();
        this.router.navigate([TextConstants.APP_URL_ROUTE_TEAM_MEMBER_HOME]);
      },
      error: (error) => {
        this.error = error.detail ?? error.errors.Email[0];
      },
    });

    this.email = '';
    this.password = '';
  }
}
